<template>
  <div class="image-card card">
    <a v-if="product.isExternalLink" :href="product.externalLink">
      <img :src="product.cover" class="img"/>
    </a>

    <a v-else :href="linkToProduct">
      <img :src="product.cover" class="img"/>
    </a>
    <div class="card-body">
      <div class="me-1">
        <h3 class="title">{{ product.title }}</h3>
        <span class="subtitle" v-if="product.subtitle && false">{{ product.subtitle }}</span>
        <router-link class="success-text" :to="{name: 'AboutProduct', params: {id: product.id, product: product}}">{{ $t('details') }}</router-link>
      </div>
      <a v-if="product.isExternalLink" :href="`${product.externalLink}?token=${encToken}&Hb_token=${hbToken}`" class="gray-btn">{{ $t('getAPolicy') }}</a>
      <a v-else :href="linkToProduct" class="gray-btn">
        {{ $t('getAPolicy') }}
      </a>
    </div>
  </div>
</template>

<script>
import InsuranceModal from "./Modals/InsuranceModal";
import {mapGetters} from "vuex";

export default {
  name: "InsuranceCard",
  props: {
    product: {
      type: Object
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    InsuranceModal
  },
  computed: {
    ...mapGetters({
      authStatus: 'status'
    }),
    appUrl(){
      return process.env.VUE_APP_URL;
    },
    encToken() {
      return this.$store.getters['auth/enc'] || localStorage.getItem('enc')
    },
    hbToken() {
      return this.$store.getters.homebankToken
    },
    linkToProduct() {
      let route =  this.$router.resolve({name: 'insurance.products', params: {id: this.product.id, form_component: this.product.form_component }})
      let baseUrl = this.$store.getters.appUrl + route.href;

      if (!this.authStatus && route.resolved.meta?.requiresAuth && this.$store.getters.isNewVersionHB) {
        return `${this.$store.getters.hbUrl}${baseUrl}`
      }

      return baseUrl
    }
  },
  methods: {
    showModal() {
      this.$modal.show(InsuranceModal, {info: this.product}, this.$modalConfig,)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";

.image-card {
  overflow: hidden;

  .gray-btn {
    width: 200px;
  }

  .img {
    width: 100%;
    height: 220px;
    -o-object-fit: contain;
    -o-object-position: center;
    object-position: center;
    object-fit: contain;
  }

  .card-body {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: 16px;
    }

    .subtitle{
      font-family: 'Manrope';
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #566681;
      display: block;
      margin-bottom: 4px;
    }
    .success-text {
      font-weight: bold;
      font-size: 12px;
      color: $primary-color;
    }
  }
}

@media (max-height: 700px) {
  .image-card {
    .img {
      height: 150px;
    }
  }
}

@media (max-width: 375px) {
  .image-card {
    .img {
      height: 150px;
    }

    .card-body {
      .title {
        font-size: 14px;
      }

      .success-text {
        font-size: 10px;
      }
    }
  }
}
</style>
